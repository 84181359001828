import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';

import {KeycloakService} from 'keycloak-angular';
import {User} from '../../../../core/models/users/users.model';
import {splitTopics} from '../../../../core/utils/topics.helper';
import {Topic} from '../../../../core/models/topics/topics.model';
import {clients} from '../../../../core/constants/clients.config';
import {clientRoles} from 'src/app/core/constants/client-roles.config';
import {Article} from '../../../../core/models/articles/articles.model';
import {TopicsService} from '../../../../core/services/topics/topics.service';
import {ArticlesFilter, parseArticleQuery, writeArticleQuery} from '../../../../core/models/articles/articles.filter';
import {ArticlesService} from '../../../../core/services/articles/articles.service';
import {UsersService} from '../../../../core/services/users/users.service';
import {map, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-manage-articles',
  templateUrl: './manage-articles.component.html',
  styleUrls: ['./manage-articles.component.css']
})
export class ManageArticlesComponent implements OnInit {
  // user permissions
  public clientRoles = clientRoles;
  public userRoles: string[] = [];

  // data
  public articles!: Article[];
  public topics: Topic[] = [];
  public subjectAreas: Topic[] = [];

  // pagination
  public collectionSize = 0;

  // params
  public articlesFilter: ArticlesFilter = {
    sortBy: 'updatedAt',
    descending: true,
    pageSize: 10,
    selectedPage: 1,
    populate: ['authorId'],
    select: ['title', 'shortDescription', 'description', 'updatedAt', 'authorId'],
  };

  private user?: User;

  constructor(
    private articlesService: ArticlesService,
    private topicsService: TopicsService,
    public keycloakService: KeycloakService,
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    this.userRoles = this.keycloakService.getUserRoles(false, clients.API);
    this.user = await this.usersService.getCurrentUser();

    this.route.queryParams.pipe(
      map(params => {
        parseArticleQuery(this.articlesFilter, params);

        if (this.route.snapshot.data.user) {
          this.articlesFilter.curAuthor = this.user!._id;
        }

        return this.articlesFilter;
      }),
      switchMap(filter => this.articlesService.getAll(filter)),
    ).subscribe(res => {
      this.articles = res.docs;
      this.collectionSize = res.total;
    });

    this.topicsService.getAll().then((res) => {
      const splittedTopics = splitTopics(res.docs);
      this.topics = splittedTopics.topics;
      this.subjectAreas = splittedTopics.subjectAreas;
    });
  }

  public addArticle(): void {
    this.router.navigate(['/author/article/create']);
  }

  public handlePageChange(): void {
    this.getArticles(true);
  }

  public changeSort(sort: { sortBy: string, descending: boolean }): void {
    this.articlesFilter.sortBy = sort.sortBy;
    this.articlesFilter.descending = sort.descending;

    this.getArticles();
  }

  public applyArticleFilter(filter: ArticlesFilter): void {
    Object.assign(this.articlesFilter, filter);
    this.getArticles();
  }

  private getArticles(onlyPageChange?: boolean): void {
    if (!onlyPageChange) {
      this.articlesFilter.selectedPage = 1;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: writeArticleQuery(this.articlesFilter),
    });
  }
}
