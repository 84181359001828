import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {Subscription} from 'rxjs';
import {Topic} from '../../../../core/models/topics/topics.model';
import {Article} from '../../../../core/models/articles/articles.model';
import {TopicsFilter} from '../../../../core/models/topics/topics.filter';
import {TopicsService} from '../../../../core/services/topics/topics.service';
import {Institution} from '../../../../core/models/institutions/institutions.model';
import {ArticlesService} from '../../../../core/services/articles/articles.service';
import {InstitutionsService} from '../../../../core/services/institutions/institutions.service';
import {ToastService} from "../../../../core/services/toast/toast.service";

@Component({
  selector: 'app-edit-article',
  templateUrl: './edit-article.component.html',
  styleUrls: ['./edit-article.component.css']
})
export class EditArticleComponent implements OnInit, OnDestroy {
  @ViewChild('articleFormComponent') articleForm: any;

  private paramsSub!: Subscription;
  private articleId!: string;
  private article!: Article;
  public topics!: Topic[];
  public subjectAreas!: Topic[];
  public institutions!: Institution[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private articlesService: ArticlesService,
    private topicsService: TopicsService,
    private institutionsService: InstitutionsService,
    private toastService: ToastService,
  ) {
  }

  public ngOnInit(): void {
    this.getTopics();
    this.getSubjecAreas();
    this.getInstitutions();

    this.paramsSub = this.route.params.subscribe((params) => {
      this.articleId = params.id;
      this.getArticle();
    });
  }

  public ngOnDestroy(): void {
    this.paramsSub.unsubscribe();
  }

  private getArticle() {
    this.articlesService.getOne(this.articleId).subscribe((article: Article) => {
      this.article = article;
      this.articleForm.updateValues(this.article);
    });
  }

  private getTopics(): void {
    const queryParams: TopicsFilter = {
      isSubjectArea: 'false',
    };

    this.topicsService.getAll(queryParams).then((topics) => {
      this.topics = topics.docs;
    });
  }

  private getSubjecAreas(): void {
    const queryParams: TopicsFilter = {
      isSubjectArea: 'true',
    };

    this.topicsService.getAll(queryParams).then((subjectAreas) => {
      this.subjectAreas = subjectAreas.docs;
    });
  }

  saveArticle() {
    this.articlesService.updateOne(Object.assign(this.article, this.articleForm.getValues()), this.articleId).then(() => {
      this.router.navigate(['../..'], {relativeTo: this.route});
      this.toastService.success('Artikel bearbeitet', 'Der Artikel wurde erfolgreich bearbeitet.');
    }).catch(error => {
      this.toastService.error('Artikel nicht bearbeitet', 'Der Artikel konnte nicht bearbeitet werden.', error);
    });
  }

  private getInstitutions() {
    this.institutionsService.getAll().then((instData) => {
      this.institutions = instData.docs;
    });
  }
}
