<div class="ui grid">
  <div class="row">
    <div class="fifteen wide column">
      <h1 class="ui header">Artikel verwalten: </h1>
    </div>
    <div class="one wide column">
      <div class="ui right floated labeled icon primary button" (click)="addArticle()" (keydown)="addArticle()"
           tabindex="-1">
        <i class="plus square icon"></i>
        Erstellen
      </div>
    </div>
  </div>
  <div class="row">
    <div class="sixteen wide column">
      <app-article-filter [topics]="topics" [subjectAreas]="subjectAreas" [numberOfArticles]="collectionSize"
                          (applied)="applyArticleFilter($event)"
                          [isAdmin]="userRoles.includes(clientRoles.IS_ADMIN)"></app-article-filter>
    </div>
  </div>
  <div class="row">
    <div class="sixteen wide column">
      <div class="ui segment">
        <app-articles-sort (sortParamSelected)="changeSort($event)"></app-articles-sort>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="sixteen wide column">
      @if (articles) {
        <app-articles-list [articles]="articles" [showAuthor]="true" [showUpdatedAt]="true"
                           [showActions]="true"></app-articles-list>
      }
    </div>
  </div>
  <!--PAGINATION-->
  <div class="centered row">
    <sui-pagination [maxSize]="5" [canRotate]="true" [hasEllipses]="true" [collectionSize]="collectionSize"
                    [pageSize]="articlesFilter.pageSize!" [hasNavigationLinks]="true" [hasBoundaryLinks]="true"
                    [(page)]="articlesFilter.selectedPage!" (pageChange)="handlePageChange()">
    </sui-pagination>
  </div>
</div>
